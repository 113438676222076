<template>
  <div>
    <b-button
      size="sm"
      title="Export"
      class="button-export-style mr-1 ml-1 button-white-with-icon-color"
      @click="handleExportFile"
    >
      <font-awesome-icon icon="cloud-download-alt"
    /></b-button>
  </div>
</template>

<script>
export default {
  props: {
    filter: { required: true },
    genereRapport: { required: true }
  },
  methods: {
    async handleExportFile() {
      const response = await this.genereRapport(this.filter);
      if (response.succes) {
        this.$swal.fire({
          title: 'Votre fichier sera bientôt prêt !',
          type: 'confirm',
          icon: 'success',
          locale: 'fr',
          stopKeydownPropagation: false,
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          cancelButtonColor: '#d33',
          confirButtonColor: '#218838',
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-2 ',
            confirmButton: 'order-1'
          }
        });
      } else {
        this.$swal.fire({
          title: response.error,
          type: 'confirm',
          icon: 'error',
          locale: 'fr',
          stopKeydownPropagation: false,
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          cancelButtonColor: '#d33',
          confirButtonColor: '#218838',
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-2 ',
            confirmButton: 'order-1'
          }
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.sort-icon {
  height: 20px;
  width: 20px;
}
</style>
<style lang="scss">
.align-item-extract-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-extaction-bdd-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-colonne-horizontal,
  #fieldset-headers-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
#info-wrap {
  /* overflow to handle inner floating block */
  overflow: hidden;
}

.description-column {
  width: 90%;
  float: left;
}
.info-column {
  width: 10%;
  float: left;
}
</style>
